function setUpCraousel() {
  let slide = 0;
  const count = 3

  const container = document.querySelector('.carousel-container');
  const slideAt = (i) => container.querySelector(`.slide-${i}`);

  function nextSlide() {
    return (slide + 1) % count;
  }

  container.addEventListener('click', () => {
    if (container.disabled) return;

    const current = slideAt(slide);
    slide = nextSlide();
    const next = slideAt(slide);

    next.classList.add('fade-in');

    setTimeout(() => {
      current.classList.remove('current');
      next.classList.remove('fade-in');
      next.classList.add('current');
    }, 1000);

    container.disabled = true;
    setTimeout(() => {
      container.disabled = false;
    }, 500);
  });
}

function setupTogglables() {
  document.querySelectorAll('.article-toggle').forEach((article) => {
    article.addEventListener('click', () => {
      if (document.body.clientWidth > 1070) return;

      dataLayer.push({'event': article.dataset.name})

      const open = article.classList.contains('article-open');

      if (open) {
        article.classList.remove('article-open');
      } else {
        article.classList.add('article-open');
      }
    });
  });
}

setUpCraousel();
setupTogglables();
